/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

@font-face {
  font-family: "Sohn Bold";
  src: url("./assets/fonts/sohn/sohn-bold.woff2") format("woff2"),
    url("./assets/fonts/sohn/sohn-bold.otf") format("otf"),
    url("./assets/fonts/sohn/sohn-bold.ttf") format("ttf");
}

@font-face {
  font-family: "Sohn Normal";
  src: url("./assets/fonts/sohn/sohn-normal.otf") format("otf"),
    url("./assets/fonts/sohn/sohn-normal.woff2") format("woff2"),
    url("./assets/fonts/sohn/sohn-normal.ttf") format("ttf");
}
@font-face {
  font-family: "Sniglet";
  src: url("./assets/fonts/Sniglet/Sniglet-Regular.ttf") format("ttf");
}
html,
body {
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100vh;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Sniglet, "Sohn Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  line-height: 1.2;
  margin: 0;
  padding: 0;
}

ul,
ol,
figure,
p {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-position: inside;
}

a {
  color: #11b;
}

svg,
img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

img:focus {
  border: 0;
  outline: 0;
}

html,
body,
button,
input,
select,
strong,
optgroup,
textarea,
.ql-editor * {
  color: #160b0d;
  font-family: Sniglet, "Sohn Normal", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  font-size: 1em;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.site-content {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
}

.container {
  width: 100%;
  padding: 0 1em;
}

.container .large {
  max-width: 1280px;
  margin: auto;
}

.skip-to-content-link {
  display: block;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateY(-1000%);
  background: #160b0d;
  transition: transform 0.3s;
  padding: 0.3em 1em;
  text-decoration: none;
  color: #28919b;
}

.video {
  width: 100%;
}

input[type="checkbox"],
input[type="radio"],
input[type="file"] {
  cursor: pointer;
}

.button {
  outline: none;
  cursor: pointer;
  text-shadow: none;
  line-height: normal;
  background-color: transparent;
  border: 0;
  border-radius: 2px;
  font-weight: 400;
  padding: 11px 24px;
  display: inline-block;
  text-align: center;
  white-space: normal;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.1s ease;
  text-decoration: none;
  letter-spacing: 0.45px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  position: relative;
}

.details {
  padding: 4px;
  cursor: pointer;
  outline: none;
  -webkit-transition: 280ms all 120ms ease-out;
  transition: 280ms all 120ms ease-out;
}

details[open] {
  -webkit-transition: 280ms all 120ms ease-out;
  transition: 280ms all 120ms ease-out;
}

.summary {
  padding-left: 0;
  font-size: 17px;
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;
  align-items: center;
  outline: none;
  font-family: Sniglet, "Sohn Bold", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

summary::-webkit-details-marker {
  display: none;
}
.details div {
  height: fit-content;
}
.rich-text,
.rich-small,
figcaption {
  font-size: 1em;
  font-family: Sniglet, "Sohn Normal", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
}

.module-inner {
  margin: 1.5em 0;
}

.rich-small {
  font-size: 0.8em;
  line-height: 1;
}

.width-100,
.media-container {
  width: 100%;
}
.width-50 {
  width: 50px;
}
.width-300 {
  width: 300px;
}
.image-container,
.media-container {
  position: relative;
}

.image-auto {
  width: auto;
  height: auto;
}

.flex {
  display: flex;
}

.space-flex-wrap {
  justify-content: space-between;
  flex-flow: row wrap;
}

.auto {
  width: auto;
  height: auto;
}

.page-section-head {
  text-align: left;
}

.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

.bold {
  font-weight: 600;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/*
Buttons
*/

.facebook-button {
  border: 2px solid rgb(59, 89, 152);
  color: rgb(59, 89, 152);
  background-color: rgba(59, 89, 152, 0.2);
}

.twitter-button {
  border: 2px solid rgb(0, 172, 237);
  color: rgb(0, 172, 237);
  background-color: rgba(0, 172, 237, 0.2);
}

.instagram-button {
  border: 2px solid rgb(195, 42, 163);
  color: rgb(195, 42, 163);
  background-color: rgba(195, 42, 163, 0.2);
}

.google-button {
  border: 2px solid rgba(219, 68, 55, 1);
  color: rgba(219, 68, 55, 1);
  background-color: rgba(219, 68, 55, 0.2);
}

.twitter-button:hover,
.twitter-button:focus {
  background-color: rgb(0, 172, 237);
  color: #fff;
}

.facebook-button:hover,
.facebook-button:focus {
  background-color: rgb(59, 89, 152);
  color: #fff;
}

.instagram-button:hover,
.instagram-button:focus {
  background-color: rgb(195, 42, 163);
  color: #fff;
}

.google-button:hover,
.google-button:focus {
  background-color: rgba(219, 68, 55, 1);
  color: #fff;
}

.tag-button {
  font-size: 12px;
  padding: 6px 7px;
  box-shadow: none;
  color: #160b0d;
  background-color: #ddd;
  margin-bottom: 6px;
  margin-right: 3px;
  border-radius: 2px;
}

.tag-button:hover,
.tag-button:focus {
  color: #50b9e9;
  background-color: #160b0d;
}

.more-button {
  background-color: rgba(194, 203, 213, 1);
  color: #160b0d;
  text-transform: uppercase;
}

/*
Widths
*/
.fullscreen {
  width: 100%;
  height: 100vh;
  display: flex;
}

.width-960 {
  width: 960px;
  margin: auto;
}

.width-800 {
  width: 800px;
}

.width-600,
.modal-600 {
  width: 600px;
}

.width-48 {
  width: 48%;
}

/*
Spacings
*/
.section-height-300 {
  height: 300px;
}

.pull-50 {
  top: -50px;
}

.pull-80 {
  top: -80px;
}

.pull-200 {
  top: -150px;
}

.left-push-4 {
  margin-left: 4px;
}

.left-push-8 {
  margin-left: 8px;
}

.left-push-10 {
  margin-left: 10px;
}

.right-push-4 {
  margin-right: 4px;
}

.right-push-8 {
  margin-right: 8px;
}

.right-push-10 {
  margin-right: 10px;
}

.section-spacing-10 {
  padding: 10px 0;
}

.section-spacing-16 {
  padding: 16px 0;
}

.section-spacing-20 {
  padding: 20px 0;
}

.section-spacing-24 {
  padding: 24px 0;
}

.section-spacing-30 {
  padding: 30px 0;
}

.section-spacing-40 {
  padding: 40px 0;
}

.section-spacing-50 {
  padding: 50px 0;
}

.section-spacing-60 {
  padding: 60px 0;
}

.section-spacing-80 {
  padding: 80px 0;
}

.section-spacing-100 {
  padding: 100px 0;
}

.section-spacing-150 {
  padding: 150px 0;
}

.section-spacing-180 {
  padding: 180px 0;
}

.padding-4 {
  padding: 4px;
}

.padding-8 {
  padding: 8px;
}

.padding-16 {
  padding: 16px;
}

.padding-20 {
  padding: 20px;
}

.padding-24 {
  padding: 24px;
}

.margin-auto {
  margin: auto;
}

.margin-top-bottom-10 {
  margin: 10px 0;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-48 {
  margin-bottom: 48px;
}

.margin-spacing-50 {
  margin: 50px 0;
}

.margin-spacing-40 {
  margin: 40px 0;
}

.margin-spacing-30 {
  margin: 30px 0;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.padding-bottom-8 {
  padding-bottom: 8px;
}

.spaced {
  margin: 8px 0;
}
.minus-margin-top-50 {
  margin-top: -50px;
}
.minus-margin-top-80 {
  margin-top: -80px;
}
.minus-margin-top-100 {
  margin-top: -100px;
}

.spaced-left-right-4 {
  margin: 0 4px;
}

.margin-left-10 {
  margin-left: 10px;
}

.padding-top-4 {
  padding-top: 4px;
}

/*
Text styles
*/

.title.rich-text {
  letter-spacing: 1px;
}

.title {
  font-size: 36px;
}

.bold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.inherit {
  font-family: inherit;
  color: inherit;
}

.inherit-link,
.block-link {
  color: inherit;
  text-decoration: none;
}

.inherit-link:hover,
.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.side-field label {
  cursor: pointer;
}

.form-fields-container {
  justify-content: space-between;
}

/*
Blocks & Images
*/
.block {
  display: block;
}

.display-none {
  display: none;
}

.centered {
  display: flex;
  width: 100%;
}

.centered-inner {
  margin: auto;
}

.left-centered {
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.overlap {
  position: relative;
  z-index: 1;
}

.relative {
  position: relative;
}

.thumbnail {
  width: 100px;
  aspect-ratio: auto 100 / 60;
  height: 60px;
  object-position: top;
  line-height: 0;
  border-radius: 2px;
  position: relative;
  z-index: 1;
}

.thumbnail::after,
.article-big-img::after {
  position: absolute;
  content: "MALIDAD.COM";
  font-family: "Sohn Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  color: #888;
  text-align: center;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  z-index: 2;
  background-color: #ccc;
  height: 100%;
  width: 100%;
}

.loader-small-img.thumbnail::after,
.loader-item.article-big-img::after {
  display: none;
}

.article-big-img::after {
  font-size: 60px;
}

.width-max-content {
  width: fit-content;
  width: -webkit-fit-content;
  width: -o-fit-content;
  width: -moz-fit-content;
}

.box-shadow {
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
}
.text-shadow {
  text-shadow: 3px 3px rgba(0, 0, 0, 0.5);
}
.no-box-shadow {
  box-shadow: none !important;
}

.circle-radius {
  border-radius: 50%;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-50 {
  border-radius: 50px;
}

.bordered-bottom-4 {
  border-bottom: 4px solid rgba(194, 203, 213, 1);
}
.bordered-title {
  border: 2px solid #1d951a;
  width: fit-content;
  background-color: #1d951a;
  padding: 4px;
}
.top-yellow-border-2 {
  border-top: 4px solid #f62324;
}

.yellow-border {
  border: 8px solid #f62324;
}

.green-border {
  border: 8px solid #6cc04a;
}

.blue-border {
  border: 8px solid #50b9e9;
}

.dark-border {
  border: 8px solid #160b0d;
}

.align-center {
  align-items: center;
}

.align-end {
  text-align: end;
}

.align-top {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.no-line-height {
  line-height: 0;
}

.modal-container {
  position: relative !important;
  z-index: 100;
}

.modal-contents-container {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
}

.modal-contents-inner {
  margin: 5% auto;
  padding: 1em;
  border-radius: 2px;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.modal-close {
  justify-content: flex-end;
}
.show {
  display: block;
  animation: animatezoom 1s;
}
.full-height {
  height: 100%;
}

.full-screen {
  height: 100vh;
}

.half-screen {
  height: 50vh;
}

/*
Font size
*/
.font-size-24 {
  font-size: 1.5em;
}

.font-size-25 {
  font-size: 1.6em;
}

.font-size-20 {
  font-size: 1.25em;
}
.font-size-18 {
  font-size: 1.125em;
}
.font-size-16 {
  font-size: 1em;
}

/*
Colors
*/

.facebook-color {
  color: rgb(59, 89, 152);
}

.twitter-color {
  color: rgb(0, 172, 237);
}

.instagram-color {
  color: rgb(195, 42, 163);
}

.gray {
  color: #777;
}

.light-gray {
  color: #ccc;
}

.light-yellow {
  color: #f62324;
}

.light-yellow-imp {
  color: #f62324 !important;
}

.white-bg {
  background-color: #fff;
}

.dark {
  color: #160b0d;
}
.gray-bg {
  background-color: #eeeeee;
}
.dark-strips-bg {
  background-color: #160b0d;
  background-image: url("./assets/images/background/stripes.svg");
}

.threads-bg {
  background-color: transparent;
  background-image: url("./assets/images/background/black-thread-light.png");
}

.triangles-bg {
  background-color: transparent;
  background-image: url("./assets/images/background/triangles.jpg");
}

.wave-bg {
  background-color: transparent;
  background-image: url("./assets/images/background/wave.svg"),
    url("./assets/images/background/wave.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center, bottom;
}
.cloudy-bg {
  background-color: transparent;
  background-image: url("./assets/images/content/Subtract.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.wavetitle-bg {
  background-color: transparent;
  background-image: url("./assets/images/background/wave.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position: top center;
}
.thankyou {
  background-image: url("./assets/images/background/thank-you.svg");
  background-repeat: no-repeat;
  background-position: top;
}

.dark-bg {
  background-color: #160b0d;
}
.black-bg {
  background-color: #160b0d;
}
.black {
  color: #160b0d;
}
.aside-bg {
  background-color: rgba(86, 103, 122, 0.3);
}

.smoky-white {
  color: rgba(194, 203, 213, 1);
}
.smoky-bg {
  background: rgba(194, 203, 213, 1);
}
.light-dark {
  color: rgba(86, 103, 122, 1);
}

.green,
.dark-green {
  color: #1d951a;
}
.green-bg {
  background-color: #1d951a;
}
.dark-green {
  color: #1d951a;
}

.light-green {
  color: #8ad169;
}

.light-green-bg {
  background-color: #8ad169;
}

.dark-green-bg {
  background-color: #6cc04a;
}
.blue {
  color: blue;
}

.crimson {
  color: crimson;
}

.light-blue {
  color: #50b9e9;
}

.light-blue-bg {
  background-color: #50b9e9;
}

.dim-blue {
  color: #28919b;
}

.dark-blue {
  color: #009ae1;
}

.dark-blue-button,
.dark-blue-bg {
  background-color: #009ae1;
}

.yellow {
  color: yellow;
}

.light-yellow {
  color: lightgoldenrodyellow;
}
.footerImgBg {
  background-image: url("./assets/images/content/footer_bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
}
.trophyBg {
  background-image: url("./assets/images/content/trophy_icon.png");
  background-repeat: repeat;
  background-color: lightcyan;
}
.whiteBg {
  background-image: url("./assets/images/content/cta_bg_white.png");
  background-repeat: no-repeat;
}
.dark-yellow {
  color: #ffb01c;
}

.dark-yellow-bg {
  background-color: #ffb01c;
}

.dark-gray {
  color: #444;
}

.black {
  color: #160b0d;
}

.navy {
  color: hsl(210, 100%, 13%);
}
.navy-bg {
  background-color: hsl(210, 100%, 13%);
}
.light-navy-bg {
  background-color: hsl(210, 100%, 20%);
}
.light-yellow-bg {
  background-color: #f62324;
}

.white {
  color: #fff !important;
}

.light-gray-bg {
  background-color: #ccc;
}

.red {
  color: red;
}

.red-bg {
  background-color: red;
}

.purple-bg {
  background-color: plum;
}

.wheat-bg {
  background-color: wheat;
}
.pink-bg {
  background-color: #f42478;
}
a.link,
.text-centric-wrap a {
  color: #50b9e9;
}

.text-shadow {
  text-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
}
/*
Buttons
*/

.facebook-button {
  border: 2px solid rgb(59, 89, 152);
  color: rgb(59, 89, 152);
  background-color: rgba(59, 89, 152, 0.2);
}

.twitter-button {
  border: 2px solid rgb(0, 172, 237);
  color: rgb(0, 172, 237);
  background-color: rgba(0, 172, 237, 0.2);
}

.instagram-button {
  border: 2px solid rgb(195, 42, 163);
  color: rgb(195, 42, 163);
  background-color: rgba(195, 42, 163, 0.2);
}

.google-button {
  border: 2px solid rgba(219, 68, 55, 1);
  color: rgba(219, 68, 55, 1);
  background-color: rgba(219, 68, 55, 0.2);
}

.twitter-button:hover,
.twitter-button:focus {
  background-color: rgb(0, 172, 237);
  color: #fff;
}

.facebook-button:hover,
.facebook-button:focus {
  background-color: rgb(59, 89, 152);
  color: #fff;
}

.instagram-button:hover,
.instagram-button:focus {
  background-color: rgb(195, 42, 163);
  color: #fff;
}

.google-button:hover,
.google-button:focus {
  background-color: rgba(219, 68, 55, 1);
  color: #fff;
}

.light-yellow-button {
  background-color: #f62324;
  color: #160b0d;
}

.light-blue-button {
  background-color: #50b9e9;
  color: #fff;
}

.light-yellow-button:hover {
  color: #eee;
  transition: 0.3s ease;
}

.light-yellow-button:hover:after {
  position: absolute;
  content: "";
  left: 0;
  top: 100%;
  right: 0;
  width: 50%;
  margin: auto;
  height: 2px;
  background-color: #eee;
  transition: 0.3s ease;
}

.light-green-button {
  background-color: #8ad169;
  color: #160b0d;
}

.modal-close-button {
  background-color: #ff0000;
  color: #fff;
}

.modal-close-button:hover,
.modal-close-button:focus {
  color: #ccc;
}

/*
Inputs
*/

/*Custom select*/
.custom-select-container {
  display: block;
  padding: 0;
  position: relative;
  color: #160b0d;
  display: block;
  border-radius: 0;
  box-shadow: none;
  font-size: 16px;
  width: 100%;
}

.custom-select-container::after {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  z-index: 2;
}

.custom-select-container select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  font-size: 14px;
  outline: none;
  border: 2px solid #160b0d;
  /* changeable */
  cursor: pointer;
  border-radius: 2px;
  font-weight: 400;
  color: inherit;
  padding: 11px 15px;
  /* changeable */
  line-height: normal;
  -webkit-transition: border-color 0.2s ease, outline 0.2s ease;
  transition: border-color 0.2s ease, outline 0.2s ease;
  /* Focus style */
}

.custom-select-container select:focus {
  background-color: rgba(194, 203, 213, 1);
  outline: none;
  color: #000 !important;
}

.custom-select-container option {
  font-weight: normal;
  background: transparent;
  border: 0;
}

.custom-select-container x:-o-prefocus,
.custom-select-container::after {
  display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .custom-select-container select::-ms-expand {
    display: none;
  }

  .custom-select-container select:focus::-ms-value {
    background: transparent;
    color: #160b0d;
  }
}

@-moz-document url-prefix() {
  .custom-select-container {
    overflow: hidden;
  }

  .custom-select-container select {
    width: 120%;
    width: calc(100% + 3em);
    /* Firefox focus has odd artifacts around the text, this kills that. 
	See https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-focusring */
  }

  @supports (-moz-appearance: none) {
    .custom-select-container select {
      width: 100%;
    }
  }

  .custom-select-container select:-moz-focusring {
    color: transparent;
  }
}

@supports (-moz-appearance: none) {
  .custom-select-container {
    width: 100%;
  }
}

/*Arrow Icons*/
.custom-select-container::after {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  border: 1px solid transparent;
  width: 0;
  height: 0;
  right: 10px;
}

.custom-select-container::after {
  border-width: 8px 6.5px 0 6.5px;
  border-top-color: #160b0d;
  top: 42%;
}

@-moz-document url-prefix() {
  .custom-select-container,
  .custom-select-container:hover {
    border: 0;
  }
}

.custom-select-container:hover select {
  box-shadow: 0;
}

.custom-select-container:hover select:focus {
  outline: none;
}

.custom-select-container:hover::after,
.custom-select-container:focus::after {
  border-top-color: #160b0d;
}

.custom-select-container select:focus {
  box-shadow: none;
  outline: none;
}

.custom-select-container select[disabled],
.custom-select-container select:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.custom-select-container select:not(:focus):invalid {
  color: #ffb01c;
}

select:focus option[value="0"] {
  display: none !important;
}

/*End custom select*/
.custom-input-container {
  position: relative;
  padding: 0.5em 0;
  display: flex;
  align-items: flex-start;
}

.custom-input-container > * {
  cursor: pointer;
}

.custom-input-container label {
  margin-left: 0.5em;
  flex: 1;
}

.custom-input-container input {
  height: 25px;
  width: 25px;
  opacity: 0;
}

.custom-input-container input + label::before {
  border: 2.5px solid #ffb01c;
  content: "";
  height: 25px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 25px;
  border-radius: 0.25em;
}

.custom-radio input + label::before {
  border-radius: 50%;
}

.custom-input-container input + label::after {
  content: "";
  border: 4px solid #ffb01c;
  border-left: 0;
  border-top: 0;
  height: 17px;
  border-radius: 0.25em;
  left: 8px;
  opacity: 0;
  position: absolute;
  top: 10px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  width: 10px;
}

.dark.custom-input-container input + label::before,
.dark.custom-input-container input + label::after {
  border-color: #160b0d;
}

.dark-yellow.custom-input-container input + label::before,
.dark-yellow.custom-input-container input + label::after {
  border-color: #ffb01c;
}

.light-yellow.custom-input-container input + label::before,
.light-yellow.custom-input-container input + label::after {
  border-color: #f62324;
}

.custom-radio input + label::after {
  border-radius: 50%;
  left: 6.5px;
  top: 14px;
  width: 0;
  height: 0;
  border-width: 13px;
}

.custom-input-container input:checked + label::after {
  opacity: 1;
}

.input {
  outline: none;
}

.disabled,
button[disabled="disabled"],
input[type="submit"][disabled="disabled"] {
  background: none !important;
  background-color: #dddddd !important;
  color: #999999 !important;
  border-color: #dddddd !important;
  cursor: default !important;
  text-shadow: none;
  -webkit-transition: none;
  transition: none;
}

.disabled:hover,
.disabled:focus,
button[disabled="disabled"]:focus,
input[type="submit"][disabled="disabled"]:focus,
button[disabled="disabled"]:hover,
input[type="submit"][disabled="disabled"]:hover {
  transform: none !important;
}

.form-input {
  width: 100%;
  outline: none;
}

.form-input-text,
select.input-select {
  border: none;
  border-bottom: 2px solid #160b0d;
  background: rgba(86, 103, 122, 1);
  padding: 10px 8px;
  line-height: 1.4;
  width: 100%;
  border-radius: 2px;
  color: rgba(194, 203, 213, 1);
  font-family: inherit;
}

.form-input-area {
  resize: none;
  height: 160px;
}

.short-textarea {
  height: 80px;
}

.textarea-140 {
  height: 140px;
}

.textarea-100 {
  height: 100px;
}

.textarea-200 {
  height: 200px;
}

.textarea-300 {
  height: 300px;
}

.form-input-text:focus,
.form-input-area:focus {
  border: none;
  border-bottom: 2px solid rgb(80, 185, 233);
}

.form-field-label {
  font-size: 14px;
  color: #eee;
  display: block;
  margin-bottom: 4px;
  text-transform: capitalize;
  font-family: "Sohn Bold", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.form-field-label.yellow {
  color: #f62324;
}

.form-field-label.light-blue {
  color: #50b9e9;
}

.no-bold {
  font-weight: 400 !important;
}

.form-field-feedback {
  color: red !important;
  font-size: 14px;
  display: block;
}

.form-field-feedback.green {
  color: #6cc04a;
}

.upload-button-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
}

.upload-button-wrapper .input-file[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
}

.number-input::placeholder {
  font-size: 14px;
  font-weight: 400;
}

.form-item {
  margin: 16px 0;
}

.form-item:last-child {
  margin-bottom: 0;
}

.form-field-container {
  position: relative;
}

.label-subtitle {
  font-weight: 400;
  color: #888;
}

.name-value {
  margin: 0 2px;
  display: inline-block;
}

.input::placeholder,
.form-input::placeholder {
  color: rgba(194, 203, 213, 1);
}

@keyframes animatezoom {
  from {
    -webkit-transform: scale(0);
  }

  to {
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

/*
Sliding Track beg
*/

@keyframes scroll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(calc(-250px * 4.5));
    transform: translateY(calc(-250px * 4.5));
  }
}

.slider {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.slider:after,
.slider:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.slider:after {
  top: 0;
}

.slider:before {
  bottom: 0;
}

.slider .slide-track {
  -webkit-animation: scroll 20s linear infinite;
  animation: scroll 20s linear infinite;
  display: -webkit-box;
  display: flex;
  width: calc(250 * 9) px;
}
.slider .slide-track:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.slider .slide {
  height: 100%;
  padding: 4px 0;
}

/* Sliding Track end */
.designer {
  padding-top: 4px;
  border-top: 2px dotted #002;
  margin-top: 10px;
}
