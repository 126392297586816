.app {
  width: 100%;
}

.site-header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  height: 65px;
}

.nav-menu {
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.brand-logo {
  width: 50px;
}

.menu-wrapper {
  position: relative;
  border-radius: 4px;
  width: 38px;
  border: 2px solid red;
}
#toggle-responsive-menu {
  display: none;
}
.site-menu-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.nav-label {
  display: block;
  color: red;
  font-weight: 600;
  padding: 2px;
  font-size: 32px;
  line-height: 0;
  height: 36px;
  width: 38px;
  padding-top: 16px;
  border-radius: 2px;
  cursor: pointer;
}

.hide-menu {
  width: 100%;
  display: flex;
  justify-content: center;
}
.hide-menu-btn {
  color: #000;
  border: 2px solid #000;
  font-size: 30px;
  box-shadow: none;
  background-color: transparent;
  line-height: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 3px;
  margin-bottom: 10px;
  border-radius: 3px;
  font-weight: 600;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
}
.hide-menu-btn:hover {
  color: red;
  border: 2px solid red;
}

.nav-inner {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  flex-flow: column;
  width: 290px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.2);
  padding: 16px;
  border-radius: 4px;
}
.nav-menu li a {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
  font-size: 0.8em;
}

.nav-menu li a:focus,
.nav-menu li a:hover {
  color: green;
}
.site-menu-items {
  display: flex;
  flex-flow: column;
}
.site-menu-items li,
.site-menu-items li:last-child {
  padding: 10px 5px;
  margin: 10px 0;
  margin: 0;
  width: 100%;
  border-bottom: 1px dotted red;
}
.site-menu-items li:first-child {
  border-top: 1px dotted red;
}
.site-menu-items li.whatsapp-button {
  padding: 8px 16px;
  width: 100%;
  margin-top: 20px;
}

.site-menu-items li.whatsapp-button a span {
  display: none;
}
.site-menu-items li.whatsapp-button a img {
  margin: auto;
  width: auto;
}
.whatsapp-button {
  background-color: #25d366;
  padding: 4px 8px;
}
.whatsapp-button:hover {
  background-color: green;
}
.showParent {
  display: block;
  animation: animatezoom 1s;
}

.mottos-container,
.shared-section {
  width: 100%;
  justify-content: space-between;
}
.motto-item {
  width: 30%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 4px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid lightgreen;
  font-weight: 600;
}

.motto-head {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid lightgreen;
  padding-bottom: 2px;
}
.motto-head h3 {
  color: blue;
}
.motto-head img {
  width: 30px;
}
.section-head h3 {
  font-size: 3em;
  margin-bottom: 20px;
}
.textside {
  width: 45%;
}
.textside p,
.about-body p {
  font-size: 1.1em;
}
.textside h4,
.about-body h4 {
  font-size: 1.5em;
  color: #002;
  margin-bottom: 20px;
}
.textside ul,
.about-body ul {
  margin-left: 30px;
}
.textside ul li,
.about-body ul li {
  margin: 10px 0;
  display: flex;
  padding-top: 20px;
}
.textside ul li img,
.about-body ul li img {
  width: 30px;
  margin-right: 10px;
}
.about {
  background: url("./assets/images/content/diag_lines.png"),
    url("./assets/images/content/moon_smile.png"),
    url("./assets/images/content/trophy_icon.png"),
    url("./assets/images/content/diag_lines_inv.png");
  background-repeat: no-repeat;
  background-position: 0% 0%, 50% 30%, 80% 26%, 90% 90%;
}
.about .imgside {
  position: relative;
  width: 40%;
  background-image: url("./assets/images/content/sch_ill.jpg");
  background-repeat: no-repeat;
  background-position: 40% 50%;
  border-radius: 50%;
}
.sections {
  background-image: url("./assets/images/content/Subtract.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightgoldenrodyellow;
}

.sections-container {
  justify-content: space-aroud;
}
.sections-item {
  width: 30%;
  border-radius: 20px;
  padding: 1em;
  text-align: center;
  display: flex;
  flex-flow: column;
  margin: 0 20px;
}
.sections-item img {
  width: 200px;
  margin: 20px 0;
}
.sections-item h4 {
  font-size: 2em;
  margin: 20px 0;
}
.sections-item p {
  font-size: 1.1em;
}

.routines {
  background-image: url("./assets/images/content/lorry.png"),
    url("./assets/images/content/sun.png"),
    url("./assets/images/content/kite_icon.png"),
    url("./assets/images/content/4.png"),
    url("./assets/images/content/moon_smile.png"),
    url("./assets/images/content/Subtract.png");
  background-repeat: no-repeat;
  background-position: 90% 100%, 20% 10%, 90% 30%, 90% 70%, 10% 50%;
}
.routine-item {
  margin-bottom: 30px;
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
}
.routine-1-bg {
  background-color: #fd5c37;
}
.routine-1-bg-icon {
  background-color: #f33b10;
}
.routine-2-bg {
  background-color: #31c9a8;
}
.routine-2-bg-icon {
  background-color: #02b890;
}
.routine-3-bg {
  background-color: #379efd;
}
.routine-3-bg-icon {
  background-color: #2493fa;
}
.routine-4-bg {
  background-color: #fcc953;
}
.routine-4-bg-icon {
  background-color: #fdb510;
}
.routine-5-bg {
  background-color: rgba(221, 160, 221, 1);
}
.routine-5-bg-icon {
  background-color: rgba(221, 160, 221, 0.5);
}
.routine-6-bg {
  background-color: green;
}
.routine-6-bg-icon {
  background-color: lightgreen;
}
.routine-7-bg {
  background-color: #f42478;
}
.routine-7-bg-icon {
  background-color: #c05e85;
}
.routine-8-bg {
  background-color: #02b890;
}
.routine-8-bg-icon {
  background-color: #57977c;
}
.routine-iconside {
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 50%;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.routine-iconside img {
  width: 45px;
}
.routine-textside {
  padding-left: 20px;
  flex: 1;
  font-size: 0.8em;
  text-align: justify;
}
.routine-title {
  text-transform: uppercase;
  font-size: 1.5em;
}
.routine-time {
  font-size: 1em;
  margin: 1em 0;
}
.routine-desc {
  font-size: 1.3em;
  color: #000;
}

.fees-tables-container table {
  width: 100%;
}
.fees-table-item {
  margin: 40px 0;
}
.fees-table-item h3 {
  margin-bottom: 10px;
}

.fees-table,
.fees-table th,
.fees-table td {
  padding: 8px;
  border: 1px solid #000;
}
.fees-table th {
  color: #fff;
}

.tution-table th {
  background-color: green;
}

.uniforms-table th {
  background-color: blue;
}

.cocur-table th {
  background-color: red;
}

.enrollment {
  background-image: url("./assets/images/content/icon_1.png"),
    url("./assets/images/content/kite_icon.png"),
    url("./assets/images/content/rocket_icon_l.png"),
    url("./assets/images/content/sun.png");
  background-repeat: no-repeat;
  background-position: 20% 10%, 70% 5%, 80% 80%, 10% 80%;
  background-color: #341c77;
}
.enroll-container {
  justify-content: space-around;
}
.enrol-item {
  margin: 30px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.enrol-item img {
  width: 80px;
}
.tot img {
  width: 55px;
}
.enrol-item h4 {
  color: #ffc600;
  margin: 20px 0;
  font-size: 3em;
}
.enrol-item p {
  font-size: 1.5em;
}

.address-wrap,
.address-message {
  justify-content: space-between;
}
.address-message {
  align-items: baseline;
}
.social-icons a {
  display: block;
  margin: 0 10px;
}
.social-icons a img {
  width: 30px;
}
.address-message a.button {
  background-color: green;
  color: #fff;
}
.address-message a.button:hover {
  background-color: rgb(35, 234, 49);
}
.address-office {
  width: 30%;
  justify-content: right;
  display: flex;
}
.map {
  width: 60%;
}
.map iframe,
.contacts-container {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
.map iframe {
  height: 250px;
}
.contacts-item {
  display: flex;
  align-items: baseline;
  margin: 30px 0;
}
.contacts-item .contacts-text h4,
.contacts-item .contacts-text p,
.testimonial-user h4 {
  color: #000;
}
.address {
  background-image: url("./assets/images/content/cta_1-1.png"),
    url("./assets/images/content/moon_smile.png"),
    url("./assets/images/content/icon_7-2.png");
  background-repeat: no-repeat;
  background-position: 50% 80%, 5% 30%, 90% 20%;
}
.testimonials {
  background-color: beige;
  background-image: url("./assets/images/content/Subtract.png"),
    url("./assets/images/content/cta_img.png"),
    url("./assets/images/content/hape-021.png");
  background-repeat: repeat, no-repeat, no-repeat;
  background-position: 0% 0%, 90% 20%, 60% 85%;
}

.testimonials-container {
  display: flex;
  justify-content: space-around;
}
.testimonial-card {
  width: 450px;
  margin: 20px;
}
.testimonial-text {
  background-color: crimson;
  color: #fff;
  padding: 1em;
  border-radius: 4px;
}
.testimonial-user {
  text-align: center;
}
.testimonials-container .slick-arrow {
  display: none !important;
}

.testimonials-container .slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.enroll-container {
  justify-content: space-around;
  flex-flow: row wrap;
}
.enroll-item {
  flex: 1 1 30%;
  margin: 20px;
  background-color: #fcc953;
  padding: 1em;
  text-align: center;
  border-radius: 3px;
}
.enroll-item:last-child {
  margin: 20px auto;
  width: 300px;
}
.enroll-item h4 {
  color: #002;
  font-size: 1.3em;
}
.enroll-item p {
  color: #000;
  margin-top: 20px;
}
.enroll {
  background-image: url("./assets/images/content/footer_img.png"),
    url("./assets/images/content/kite_icon.png"),
    url("./assets/images/content/event_7.png"),
    url("./assets/images/content/footer_img.png");
  background-repeat: no-repeat;
  background-position: 50% 4%, 80% 20%, 20% 20%, 50% 94%;
}
.registration {
  background-image: url("./assets/images/content/cta_bg_white.png");
  background-repeat: repeat;
}
.registration-container {
  text-align: center;
}

.registration-container .reg-icon img {
  width: 150px;
}
.registration-container .reg-title h3 {
  font-size: 3em;
  color: crimson;
  margin: 40px 0;
}
.registration-container .reg-text {
  margin-bottom: 40px;
}
.registration-container .reg-cta .button {
  background-color: crimson;
  color: #fff;
}

.registration-container .reg-cta .button:hover {
  background-color: rgb(141, 9, 35);
}
.footer {
  background-image: url("./assets/images/content/footer_bg.png");
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-color: #e3f7fc;
}
.footer-menu {
  margin-bottom: 350px;
  justify-content: space-between;
}
.sch-badge img {
  width: auto;
  margin-bottom: 20px;
}
.footer li p,
.footer-nav-menu li a {
  color: #515374;
}
.footer-about {
  width: 45%;
}
.footer-nav {
  width: 45%;
}
.footer-nav-menu {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}
.footer-nav-menu li {
  margin: 10px 30px;
  margin-left: 0;
}
.footer-nav-menu li a {
  text-decoration: none;
  border-bottom: 2px solid #515374;
}
.footer-nav-menu li a:hover {
  color: #f62324;
  border-bottom: 2px solid #f62324;
}
.footer-menu-item h3 {
  margin-bottom: 20px;
  color: #fdb510;
  text-align: center;
}

.hero-content-container {
  position: absolute;
  top: 20%;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 3;
}

.hero,
.page-hero {
  height: 96vh;
  overflow: hidden;
  position: relative;
  top: 50px;
}
.page-hero {
  height: 300px;
  margin-bottom: 65px;
}
.curtain:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.best-bars-container {
  align-items: center;
  justify-content: center;
}

.best-star {
  width: 20px;
}
.best-bar {
  width: 100px;
  height: 3px;
  background-color: red;
}
.brand-section h2 {
  font-size: 5em;
  color: red;
}

.cta-section {
  justify-content: space-between;
  margin-top: 50px;
}

.cta-section .button {
  width: 230px;
  padding: 16px;
}

.best-text {
  text-shadow: 1px 1px rgba(0, 0, 0, 0.5);
  color: #fff;
}

.carousel-sized {
  width: 600px;
  margin: auto;
  text-transform: uppercase;
}

.carousel-sized .brand-section h2 {
  font-size: 4em;
}

.slogan-section .best-text {
  font-size: 4em;
}

.sliderImg {
  width: 100%;
}

.slider-items-container {
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative;
}

.hero {
  background-color: #fff;
  width: 100%;
}

.carousel-content-container,
.carous .slider-items-container .carous-image img,
.hero-content-container {
  height: 100vh;
}
.carousel-content-container::before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.hero-content-container {
  background: url("./assets/images/content/car_icon.png"),
    url("./assets/images/content/kite_icon.png"),
    url("./assets/images/content/rocket_icon_l.png"),
    url("./assets/images/content/trophy_icon.png");
  background-repeat: no-repeat;
  background-position: 15% 50%, 85% 30%, 20% 30%, 79% 52%;
}
.carous .slider-items-container .carous-image img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.carous .slider-items-container {
  padding: 0 !important;
}

.slider-items-container .slick-arrow {
  background-color: #8ad169 !important;
  border-radius: 2px;
  height: 32px;
  width: 50px;
  top: 100%;
}

.slider-items-container.gallery-container .slick-arrow{
  top: 110%;
}

.carous .slick-arrow {
  background-color: transparent;
}
.slider-items-container .slick-arrow:hover {
  background-color: #000 !important;
}
.slider-items-container.center-slide-arrows .slick-arrow {
  top: 37% !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 2;
}

.slider-items-container .slick-next {
  right: 0px;
}

.slider-items-container .slick-prev {
  left: 0px;
}

.carous .slider-items-container.center-slide-arrows .slick-arrow {
  top: 50% !important;
}
.carous .slider-items-container .slick-next {
  right: 20px;
}

.carous .slider-items-container .slick-prev {
  left: 20px;
}
.slider-items-container .slick-arrow.slick-disabled:before {
  opacity: 0;
}

.slider-items-container .slick-arrow.slick-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

.gallery-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}
.gallery-container figure {
  width: 30%;
  margin: 1em;
  border: 2px solid rgba(192, 117, 0, 0.3);
  padding: 16px;
  border-radius: 2px;
  background-color: lightyellow;
}
.slider-items-container.gallery-container{
  margin: auto;
  text-align: center;
  align-items: flex-end;
}
.slider-items-container.gallery-container figure{
  width: 300px;
  margin: auto;
}

.gallery-container figure figcaption {
  font-size: 0.7em;
}
.htMessages figure {
  margin: 1em;
  border: 2px solid rgba(192, 117, 0, 0.3);
  padding: 16px;
  border-radius: 2px;
  background-color: lightyellow;
}

.htMessages figure figcaption {
  font-size: 1em;
  text-align: center;
  text-transform: uppercase;
}
.htMessages-text p {
  margin-top: 20px;
}
.step {
  margin-bottom: 50px;
}
.step-head h2 {
  color: #25d366;
  font-size: 2.5em;
  text-decoration: underline;
  margin-bottom: 10px;
}

.step-head p {
  color: #11b;
}

.updates-card {
  padding: 8px;
  border: 2px solid #8ad169;
  border-radius: 2px;
  margin-bottom: 20px;
}
.updates-card:last-child {
  margin-bottom: 0;
}
.updates-head h3 {
  margin: 20px;
  border: 1px solid #8ad169;
  border-radius: 2px;
  padding: 10px 0;
}
.updates-details-inner p {
  text-align: justify;
  margin: 10px 0;
}

@media screen and (min-width: 960px) and (max-width: 1200px) {
  .brand-section h2 {
    font-size: 3.5em;
  }
  .slogan-section .best-text {
    font-size: 3em;
  }
  .textside {
    width: 55%;
  }
  .imgside {
    width: 40%;
  }
  .about {
    background-position: 0% 0%, 65% 24%, 80% 15%, 90% 90%;
  }
  .width-960 {
    max-width: 930px;
    width: 100%;
  }
  .testimonial-card {
    width: 400px;
  }
  .testimonials {
    background-position: 0% 0%, 95% 10%, 60% 75%;
  }
  .footer-about {
    width: 45%;
  }
  .footer-nav {
    width: 55%;
  }
  .gallery-container figure {
    width: 45%;
  }
  .address-office {
    width: 33%;
    justify-content: center;
  }
}
@media screen and (min-width: 800px) and (max-width: 959px) {
  .brand-section h2 {
    font-size: 2em;
  }
  .slogan-section .best-text {
    font-size: 1.5em;
  }
  .textside {
    width: 100%;
    max-width: 700px;
    margin: auto;
    text-align: center;
  }
  .about .imgside {
    display: none;
  }
  .sections-item {
    width: 30%;
    padding: 0.5em;
    margin: 0 10px;
  }
  .routine-item {
    width: 80%;
    margin: 30px auto;
  }
  .routines {
    background-position: 90% 100%, 0% 10%, 100% 30%, 0% 70%, 0% 50%;
  }
  .testimonial-card {
    width: 333px;
  }
  .testimonials {
    background-position: 0% 0%, 95% 10%, 60% 75%;
  }
  .footer {
    background-position: 0% 100%, 90% 80%;
  }
  .hero {
    height: 100vh;
  }
  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }

  .gallery-container figure {
    width: 45%;
  }
  .step-head h2 {
    font-size: 1.5em;
  }

  .contacts-container {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .contacts-item {
    margin: 20px 0;
  }
  .width-800,
  .width-960 {
    max-width: 760px;
    width: 100%;
  }
  .brand-section h2 {
    font-size: 4em;
  }

  .carousel .slider-items-container.center-slide-arrows .slick-arrow,
  .page-hero .slider-items-container.center-slide-arrows .slick-arrow {
    top: 80% !important;
  }
  .hero-content-container {
    background: none;
  }
}
@media screen and (min-width: 600px) and (max-width: 799px) {
  .section-head h3 {
    font-size: 2.5em;
  }
  .brand-section h2 {
    font-size: 2em;
  }
  .slogan-section .best-text {
    font-size: 1.5em;
  }
  .textside {
    width: 100%;
    max-width: 700px;
    margin: auto;
    text-align: center;
  }
  .about .imgside {
    display: none;
  }
  .sections-container {
    flex-flow: row wrap;
  }
  .sections-item {
    width: 80%;
    padding: 0.5em;
    margin: 30px auto;
  }

  .sections-item img {
    width: 150px;
    margin: 20px 0;
  }
  .routine-item {
    width: 96%;
    margin: 30px auto;
  }
  .routines {
    background-position: 90% 100%, 0% 10%, 100% 30%, 0% 70%, 0% 50%;
  }
  .testimonial-card {
    width: 500px;
  }
  .testimonials {
    background-position: 0% 0%, 95% 10%, 60% 75%;
  }
  .footer {
    background-position: 0% 100%, 90% 80%;
  }
  .hero {
    height: 100vh;
  }
  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }
  .fees-table,
  .fees-table th,
  .fees-table td {
    padding: 8px 5px;
    border: 1px solid #000;
  }
  .gallery-container figure {
    width: 258px;
    margin: 6px 0;
    padding: 15px;
  }
  .padding-16 {
    padding: 10px;
  }
  .step-head h2 {
    font-size: 1.5em;
  }
  .contacts-container {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .contacts-item {
    margin: 20px 0;
  }
  .width-800,
  .width-600,
  .width-960 {
    width: 98%;
  }
  .brand-section h2 {
    font-size: 4em;
  }

  .carousel .slider-items-container.center-slide-arrows .slick-arrow,
  .page-hero .slider-items-container.center-slide-arrows .slick-arrow {
    top: 80% !important;
  }
  .map iframe {
    height: 180px;
  }
  .address {
    background-position: 50% 80%, 5% 0%, 90% 20%;
  }
  .hero-content-container {
    background: none;
  }
}
@media screen and (max-width: 599px) {
  .section-head h3 {
    font-size: 2em;
  }
  .brand-section h2,
  .carousel-sized .brand-section h2 {
    font-size: 2.8em;
  }
  .slogan-section .best-text {
    font-size: 1.5em;
    margin-top: 10px;
  }
  .carousel-sized {
    width: 100%;
  }
  .carous .slider-items-container.center-slide-arrows .slick-arrow {
    top: 75% !important;
  }
  .hero-content-container {
    background: none;
  }

  .mottos-container {
    flex-flow: row wrap;
    justify-content: center;
  }
  .motto-item {
    width: 90%;
    margin: 20px auto;
  }
  .textside {
    width: 100%;
    max-width: 700px;
    margin: auto;
    text-align: center;
  }
  .about .imgside {
    display: none;
  }
  .textside ul,
  .about-body ul {
    margin-left: 0;
  }
  .sections-container {
    flex-flow: row wrap;
  }
  .sections-item {
    width: 95%;
    padding: 0.5em;
    margin: 30px auto;
  }

  .sections-item img {
    width: 150px;
    margin: 20px 0;
  }
  .routine-item {
    width: 96%;
    margin: 30px auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 20px 16px;
  }
  .routine-textside {
    padding-left: 0;
    flex: 1 1;
    font-size: 0.8em;
    text-align: center;
    margin-top: 10px;
  }
  .address-wrap {
    flex-flow: row wrap;
  }

  .map,
  .address-office {
    width: 100%;
  }
  .map h3 {
    text-align: center;
  }
  .address-office {
    margin-top: 20px;
    justify-content: center;
    display: flex;
  }
  .routines {
    background-position: 90% 100%, 0% 10%, 100% 30%, 0% 70%, 0% 50%;
  }
  .testimonial-card {
    width: 92%;
  }
  .enroll-item {
    flex: 1 1 100%;
    margin: 20px auto;
    padding: 0.6em;
  }
  .enroll {
    background-position: 50% 4%, 80% 20%, 20% 20%, 50% 99%;
  }
  .testimonials {
    background-position: 0% 0%, 95% 10%, 60% 75%;
  }
  .footer {
    background-position: 0% 100%, 15% 90%;
  }
  .footer-menu {
    margin-bottom: 350px;
    flex-flow: row wrap;
  }
  .footer-about,
  .footer-nav {
    width: 100%;
  }
  .footer-nav {
    order: -1;
  }
  .footer-about {
    margin-top: 20px;
  }
  .footer-nav-menu,
  .footer li p {
    display: block;
    text-align: center;
  }
  .footer-nav-menu li {
    margin: 30px;
  }
  .hero {
    height: 100vh;
  }
  .sliderImg,
  .pageSliderImg {
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
  }
  .fees-table,
  .fees-table th,
  .fees-table td {
    padding: 8px 5px;
    border: 1px solid #000;
    word-break: break-all;
    font-size: 0.8em;
  }

  .gallery-container figure {
    width: 300px;
    margin: 6px 0;
    padding: 1em;
  }
  .title {
    font-size: 2em;
    text-align: center;
  }
  .padding-16 {
    padding: 10px;
  }
  .step-head h2 {
    font-size: 1.5em;
  }
  .contacts-container {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .contacts-item {
    margin: 20px 0;
  }
  .width-800,
  .width-600,
  .width-960 {
    width: 98%;
  }

  .carousel .slider-items-container.center-slide-arrows .slick-arrow,
  .page-hero .slider-items-container.center-slide-arrows .slick-arrow {
    top: 80% !important;
  }
  .map iframe {
    height: 180px;
  }
  .address {
    background-position: 0% 30%, 5% 0%, 87% 59%;
  }
  .enrollment {
    background-position: 20% 10%, 70% 5%, 80% 80%, 100% 100%;
  }
}
